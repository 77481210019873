<template>
    <div>
        <div v-if="prepaidConfirmation || paymentConfirmation" class="absolute top-0 left-0 blur-lg backdrop-blur-lg bg-[#211C1F] h-full w-full bg-opacity-40"></div>
        <div class="modal-window-prepaid w-[100%] z-[100] rounded-[6px] px-[15px] py-[3px] absolute top-[50%] translate-y-[-50%]" v-if="paymentConfirmation">
            <div class="text-center w-[100%] text-[#BD252C] text-[28px]">Підтвердіть проведення оплати</div>
            <div class="mt-[20px] flex flex-col">
                <button @click="processPayment" class="w-[355px] h-10 bg-[#48E5C2] rounded-[9px] border border-[#48E5C2] font-semibold text-white">Так, прийняти оплату</button>
                <button @click="paymentConfirmation = false" class="w-[355px] mt-[20px] h-10 bg-pink-600 rounded-[9px] border border-pink-600 font-semibold text-white">Відміна</button>
            </div>
        </div>
        <div class="modal-window-prepaid w-[100%] z-[100] rounded-[6px] px-[15px] py-[3px] absolute top-[50%] translate-y-[-50%]" v-if="prepaidConfirmation">
            <div class="text-center w-[100%] text-[#BD252C] text-[28px]">Підтвердіть проведення оплати</div>
            <div class="mt-[20px] flex flex-col">
                <button @click="processIncome" class="w-[355px] h-10 bg-[#48E5C2] rounded-[9px] border border-[#48E5C2] font-semibold text-white">Так, прийняти оплату</button>
                <button @click="prepaidConfirmation = false" class="w-[355px] mt-[20px] h-10 bg-pink-600 rounded-[9px] border border-pink-600 font-semibold text-white">Відміна</button>
            </div>
        </div>
        <div @click="$router.push('/')" class="flex text-[#FECC00] items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#FECC00" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2">
                Назад
            </div>
        </div>
        <div>
            <div 
                v-if="!prepaidConfirmation && !paymentConfirmation"
                class="flex justify-between mt-4 mx-5 border-[1px] rounded-t-[10px] border-[#8C8C8C] px-2 py-[7px]"
                :class="{
                    'rounded-b-[10px]' : searchQuery.length === 0
                }"    
            >
                <div class="mr-[10px] relative top-[2px]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
                        <path d="M18 18L13.8033 13.8033M16 8.5C16 4.35786 12.6421 1 8.5 1C4.35786 1 1 4.35786 1 8.5C1 12.6421 4.35786 16 8.5 16C12.6421 16 16 12.6421 16 8.5Z" stroke="#8C8C8C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <input v-model="searchQuery" type="text" class="w-full text-[#8C8C8C] outline-none bg-transparent placeholder:text-[#8C8C8C] placeholder:opacity-60" placeholder="Введіть ім'я або фамілію учня">
            </div>
            <div v-if="searchQuery.length > 0" class="absolute w-[90vw] left-[50%] translate-x-[-50%] h-[300px] overflow-auto">
                <div v-for="student in allStudentByTrainerGroupsComputed" @click="processNewTransactionStudent(student)" class="py-3 px-2 bg-[#010101] border-b-[1px] w-full border-[#010101]">
                    <div>
                        <div class="text-[18px] font-semibold text-[#FFF]">
                            {{ student.name }} {{ student.surname }}
                        </div>
                        <div class="text-[14px] text-[#FFF]">
                            {{ student.group_name }}
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="!currentTransactionStudent.student_id" class="text-center text-[21px] text-[#FFF] flex justify-center items-center h-[80vh]">
                <span>Виберіть учня для проведення зарахування</span>
            </div>
            <div v-else class="mt-[20px]">
                <div class="flex items-center justify-center mt-[35px]">
                    <div class="pl-3 pr-2 w-[14%] py-2 text-start text-sm">
                        <div class="w-10 h-10 border-[#91CBE5] border-[1px] rounded-full overflow-hidden">
                            <img v-if="currentTransactionStudent.photo" :src="apiURL + 'static/' + currentTransactionStudent.photo">
                            <div v-else class="h-full w-full flex justify-center items-center text-[#91CBE5] bg-[#91CBE5] bg-opacity-20">{{ currentTransactionStudent.name[0] + currentTransactionStudent.surname[0] }}</div>
                        </div>
                    </div>
                    <div 
                        class="px-3 truncate py-2 text-start text-sm "
                    >
                        <div @click="$emit('openStudentCard')" class="flex flex-col align-middle cursor-pointer">
                            <div class="truncate text-[#fff] font-bold">{{ currentTransactionStudent.surname }} {{ currentTransactionStudent.name }}</div>
                            <div class="truncate text-[10px] text-[#FFF]">{{ '#' + currentTransactionStudent.player_number + ' | ' + currentTransactionStudent.amplua + ' | ' + currentTransactionStudent.age + 'p. | ' + currentTransactionStudent.nationality + ' | ' +  currentTransactionStudent.birthday.split('T')[0].split('-').reverse().join('.')}}</div>
                        </div>
                    </div>
                    <div class="text-white font-bold">
                        {{ currentTransactionStudent.group_name }}
                    </div>
                </div>

                <div class="mt-[15px]">
                    <div class="py-[10px] outline-none border-none text-4xl font-semibold text-[#FFF] bg-transparent placeholder:text-[#FFF] placeholder:opacity-60  text-center"><span class="text-[24px]">₴</span> {{ currentTransactionStudentBalance.toFixed(2) >= 0 ? (0).toFixed(2) : currentTransactionStudentBalance.toFixed(2) }}</div>
                    <div class="text-neutral-400 leading-[0] text-sm text-center">(оплата за попредній місяць)</div>
                </div>

                <button v-if="!prepaidConfirmation && !paymentConfirmation" @click="paymentConfirmation = true" :disabled="currentTransactionStudentBalance >= 0" class="mt-[50px] bg-[#48E5C2] rounded-[9px] disabled:opacity-70 text-[#fff] px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mx-auto">
                    <div class="w-full">Провести оплату</div>
                </button>

                <div class="mt-[50px] border-[#48E5C2] border-[1px] text-center rounded-[9px] text-[#48E5C2] px-3 py-2 w-[92%] text-base flex items-center align-middle justify-center mx-auto">
                    Внести іншу суму
                </div>

                <div class="mt-[15px]">
                    <input v-if="!prepaidConfirmation && !paymentConfirmation" v-model="incomeSum" class="bg-transparent text-4xl w-full text-center text-[#BD252C] outline-none border-none placeholder:text-[#BD252C] py-[20px] placeholder:opacity-50 font-semibold" type="number" placeholder="0.00">
                    <div class="text-neutral-400 leading-[0] text-sm text-center">(введіть власну суму, яку необхідно)</div>
                </div>

                <div @click="prepaidConfirmation = true" class="mt-[50px] border-[#BD252C] border-[1px] text-center rounded-[9px] text-[#BD252C] px-3 py-2 w-[92%] text-base flex items-center align-middle justify-center mx-auto">
                    Провести оплату
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { onMounted, ref, computed } from 'vue'
    import { useRouter } from 'vue-router'
    import { useMainStore } from '../stores/mainStore'

    export default {
        name: 'receive-money-view', 

        setup() {
            const store = useMainStore()
            const router = useRouter()
            const allStudentByTrainerGroups = ref([]) 
            const searchQuery = ref('')
            const currentTransactionStudent = ref({})
            const currentTransactionStudentBalance = ref(0)
            const incomeSum = ref(null)
            const allStudentByTrainerGroupsComputed = computed(() => {
                if (searchQuery.value === '') {
                    return allStudentByTrainerGroups.value
                }
                return allStudentByTrainerGroups.value.filter((item) => item.name.toLowerCase().includes(searchQuery.value.toLowerCase()) || item.surname.toLowerCase().includes(searchQuery.value.toLowerCase()))
            })
            
            const paymentConfirmation = ref(false)
            const prepaidConfirmation = ref(false)

            onMounted(async () => {
                allStudentByTrainerGroups.value = await store.getAllStudentsByTrainerGroups()
            })

            const processNewTransactionStudent = async (student) => {
                currentTransactionStudent.value = student
                searchQuery.value = ''
                currentTransactionStudentBalance.value = await store.getVirtualStudentBalance(student.balance_id)
            }
            const apiURL = ref(process.env.VUE_APP_API_URL)

            const processIncome = async () => {
                if (incomeSum.value > 0) {
                    const res = await store.proccessIncomeTransaction(
                        store.trainer.student_balance_id,
                        currentTransactionStudent.value.balance_id,
                        +incomeSum.value,
                        store.trainer.name + ' ' + store.trainer.surname,
                        currentTransactionStudent.value.name + ' ' + currentTransactionStudent.value.surname
                    )
                    if (res) {
                        router.push('/success-transaction-view')
                    }
                }
            }

            const processPayment = async () => {
                if (currentTransactionStudentBalance.value < 0) {
                    const res = await store.proccessIncomeTransaction(
                        store.trainer.student_balance_id,
                        currentTransactionStudent.value.balance_id,
                        +currentTransactionStudentBalance.value * -1,
                        store.trainer.name + ' ' + store.trainer.surname,
                        currentTransactionStudent.value.name + ' ' + currentTransactionStudent.value.surname
                    )
                    if (res) {
                        router.push('/success-transaction-view')
                    }
                }
            }

            return {
                store,
                allStudentByTrainerGroups,
                searchQuery,
                allStudentByTrainerGroupsComputed,
                currentTransactionStudent,
                currentTransactionStudentBalance,
                processNewTransactionStudent,
                incomeSum,
                processIncome,
                apiURL,
                paymentConfirmation,
                prepaidConfirmation,
                processPayment
            }
        }
    }
</script>
