<template>
    <div>
        <div @click="$router.push('/')" class="flex text-[#FECC00] items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#FECC00" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2">
                Назад
            </div>
        </div>

        <div>
            <div class="text-center mt-[20px] mb-[10px] font-semibold text-[#FFF]">
                найближче тренування
            </div>
            <button @click="selectTrainingToStart(store.allPlanedTrainings[0])" class="bg-[#FECC00] rounded-[9px] text-[#211C1F] px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mx-auto">
                <div class="w-full font-semibold">{{ store.allPlanedTrainings[0].group_label }}</div>

                <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 5L0.5 9.33013L0.5 0.669872L8 5Z" fill="#211C1F"/>
                </svg>
            </button>
            <div class="text-[#FFF] text-center mt-2">
                <span class="mr-2">{{ getWeekDayShortNameByDate(store.allPlanedTrainings[0].date) }}.</span>
                <span class="mr-2 font-semibold">{{ formateDate(store.allPlanedTrainings[0].date) }}</span>
                <span class="mr-2">{{ store.allPlanedTrainings[0].start_time }}</span>
                <span class="mr-2">{{ store.allPlanedTrainings[0].location_label }}</span>
            </div>
            <Timer class="mt-2" :targetDate="store.allPlanedTrainings[0].date.split('T')[0]" :targetTime="store.allPlanedTrainings[0].start_time"/>
        </div>

        <div class="mt-[30px]">
            <button v-for="training in store.allPlanedTrainings.slice(1)" @click="selectTrainingToStart(training)" class="border-[#FECC00] border-[1px] text-[#FECC00] mb-2 rounded-[9px] relative px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mx-auto">
                <div class="text-[13px]">{{ formateDate(training.date) + ' ' + training.start_time }}</div>
                <div class="font-semibold absolute left-[50%] translate-x-[-50%]">{{ training.group_label }}</div>


                <!-- <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 5L0.5 9.33013L0.5 0.669872L8 5Z" fill="#211C1F"/>
                </svg> -->
            </button>
        </div>
    </div>
</template>

<script>
    import { ref } from 'vue'
    import { useRouter } from 'vue-router'

    import { useMainStore } from '../stores/mainStore'

    import Timer from '../components/ui/Timer.vue'

    export default {
        name: "all-trainings-view",

        setup() {
            const store = useMainStore()
            const router = useRouter()
            const targetDate = ref(new Date(`${store.allPlanedTrainings[0].date.split('T')[0]} ${store.allPlanedTrainings[0].start_time}`))
            
            const getWeekDayShortNameByDate = (date) => {
                const weekDays = ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
                const dateObj = new Date(date)
                return weekDays[dateObj.getDay()]
            }

            const formateDate = (date) => {
                const dateObj = new Date(date)

                const monthDay = dateObj.getDate() < 10 ? `0${dateObj.getDate()}` : dateObj.getDate()
                const month = dateObj.getMonth() + 1 < 10 ? `0${dateObj.getMonth() + 1}` : dateObj.getMonth() + 1
                const year = dateObj.getFullYear()

                return `${monthDay}.${month}.${year}`
            }

            const selectTrainingToStart = async (training) => {
                await store.getAllStudentByGroupId(training.group_id)
                store.currentTraining = training
                router.push('/student-roll-call')
            }

            return {
                store,
                getWeekDayShortNameByDate,
                formateDate,
                selectTrainingToStart
            }
        },

        components: {
            Timer
        }
    }
</script>
