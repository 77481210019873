<template>
    <div class="w-full pb-[3px] pt-[2px] bg-[#FECC00] z-10 absolute">
        <div class="flex mx-4 justify-between">
            
            <div class="w-[6%]">
                <div @click="$router.push('/all-messages')" class="relative top-[6px]">
                    <img src="@/mobileApp/assets/bell_icon.svg" class="bell">
                </div>
            </div>
            <div class="text-2xl w-[60%] text-[#2B2A29] font-bold inline-block uppercase bell">{{ store.trainer.name + ' ' + store.trainer.surname }}</div>
            <div class="flex justify-end w-[30%]">
                <div class="flex" >
                    <div class="flex text-[#2B2A29] items-center align-middle">
                        <img src="../../assets/logo.svg" class="w-[20px] mr-[6px]">
                        <div class="mt-[-2px]">
                            |
                        </div>
                        <svg class="ml-2" width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 2C0 0.89543 0.895431 0 2 0H18.7692C19.8738 0 20.7692 0.895431 20.7692 2V7.8H0V2Z" fill="#417FC8"/>
                            <path d="M0 7.79999H20.7692V13C20.7692 14.1046 19.8738 15 18.7692 15H2C0.895431 15 0 14.1046 0 13V7.79999Z" fill="#ECC573"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex overflow-x-auto whitespace-nowrap mx-2 mb-1">
            <div v-for="group in store.trainer_groups" @click="selectGroup(group)" class="group-card bg-[#FECC00] h-[28px] bg-opacity-[0.5] border-[1px] border-[#C59E00] border-opacity-[0.4] px-[15px] rounded-[6px] mt-[2px] text-[#2B2A29] text-center inline-block mr-3">
                <div class="text-[8px] font-light leading-[13px]">
                    група
                </div>
                <div class="font-bold leading-[12px] text-[12px]">
                    {{ group.name }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { useRouter } from 'vue-router'
    import { useMainStore } from '../../stores/mainStore'

    export default {
        name: "trainer-data",

        setup() {
            const store = useMainStore()
            const router = useRouter()

            const selectGroup = (group) => {
                store.currentGroup = group
                router.push('/group-view')
            }

            return {
                store,
                selectGroup
            }
        }
    }
</script>

<style lang="scss" scoped>
    .bell {
        filter: drop-shadow(0px 4px 4px rgb(0 0 0 / 0.4));
    }

    .group-card {
        filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
    }
</style>