<template>
  <!-- <div v-if="loadingSpinnerFlag" class="bg-[#211C1F] z-50 fixed w-full h-full">
    <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#BD252C] border-t-transparent absolute top-[50%] left-[50%] ml-[-20px]"></div>
  </div>
  <router-view v-else/> -->
    <div class="flex justify-center absolute top-[20px] left-[50%] translate-x-[-50%]">
            <img src="@/mobileApp/assets/sportkit_logo.svg" alt="">
        </div>
        <div class="text-white absolute top-[50%] translate-y-[-50%] left-[50%] translate-x-[-50%]">
            Десктопна версія тренера наразі недоступна
        </div>
        <div 
            class="absolute bottom-[20px] left-[50%] translate-x-[-50%]"
            :class="{
                'w-[300px]' : screenWidth > 700,
                'w-full' : screenWidth <= 700
            }"    
        >
            <div class="text-[#fff] text-[11px] text-center w-full">
                © 2023 ALL RIGHTS RESERVED
            </div>
      </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { useMainStore } from './stores/mainStore'
import { useRouter, useRoute } from 'vue-router'

export default {
    name: 'main-view',

    setup() {
        const store = useMainStore()
        const router = useRouter()
        const route = useRoute()

        const loadingSpinnerFlag = ref(true)

        const logout = () => {
            localStorage.removeItem('access_token')
            localStorage.removeItem('refresh_token')
            router.push('/login')
            loadingSpinnerFlag.value = false
        }

        onMounted(async () => {
          if (localStorage.getItem('accessToken')) {
            router.push('/')
          }

          if (route.path == '/login') {
            loadingSpinnerFlag.value = false
            return
          }
          try {
            const resAuth = await store.getTrainerData()
            if (resAuth.status == 422) {
              console.log('error')
              logout()
            }
            await store.getStudentBalanceData()
            await store.getServiceBalanceData()
            await store.getTrainerGroups()
            await store.getAllTrainerPlanedTrainings()
            await store.getTrainerRealIncomes()
            loadingSpinnerFlag.value = false
          } catch (e) {
            console.log(e)
          }
        })

        return {
            logout,
            loadingSpinnerFlag
        }
    },
}

</script>

<style lang="scss">
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  body {
    background-color: #211C1F;
  }

  body::-webkit-scrollbar {
    width: 5px;               /* ширина всей полосы прокрутки */
  }

  body::-webkit-scrollbar-track {
      background: transparent;        /* цвет зоны отслеживания */
  }

  body::-webkit-scrollbar-thumb {
      background-color: #91CBE5;    /* цвет бегунка */
      border-radius: 20px;       /* округлось бегунка */ /* отступ вокруг бегунка */
  }
</style>