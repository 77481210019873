<template>
    <svg :width="fullWidth" :height="height">
        <rect :key="index" :x="0" :y="0" :width="firstPartWidth" :height="height - 15" fill="#48E5C2" />
        <rect :key="index" :x="firstPartWidth" :y="0" :width="secondPartWidth" :height="height - 15" fill="#A1D9F7" />
        <text :key="index" :x="borderGap(firstPart)" :y="height" text-anchor="middle" font-size="12px" fill="#48E5C2">{{ firstPart }}</text>
        <text :key="index" :x="fullWidth - borderGap(secondPart)" :y="height" text-anchor="middle" font-size="12px" fill="#A1D9F7">{{ secondPart }}</text>
    </svg>
</template>

<script>
export default {
    props: {
        firstPart: {
            type: Number,
            required: true,
            default: 50
        },
        secondPart: {
            type: Number,
            required: true,
            default: 50
        },
        fullWidth: {
            type: Number,
            required: true,
            default: 300
        },
        height: {
            type: Number,
            default: 27,
        },
    },
    computed: {
        firstPartWidth() {
            return (this.fullWidth / 100) * ((100 * this.firstPart) / (this.firstPart + this.secondPart));
        },
        secondPartWidth() {
            return (this.fullWidth / 100) * ((100 * this.secondPart) / (this.firstPart + this.secondPart));
        },
        borderGap() {
            return value => `${value}`.length * 5;
        },
    },
};
</script>