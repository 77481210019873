<template>
    <div>
        <div @click="$router.push('/')" class="flex text-[#FECC00] items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#FECC00" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2">
                Назад
            </div>
        </div>

        <div v-if="allInGroupStudents.length == 0" class="bg-[#211C1F] fixed w-full h-full z-[140]">
            <div class="w-10 h-10 rounded-full animate-spin border-solid border-4 border-[#BD252C] border-t-transparent absolute top-[45%] left-[50%] ml-[-20px]"></div>
        </div>

        <div class="px-4 mt-3 overflow-y-auto whitespace-nowrap">
            <div v-for="student in allInGroupStudents" class="flex mb-2">
                <div class="w-[70%] bg-[#A1D9F7] text-[18px] py-[10px] pl-2 rounded-l-[10px] font-semibold">
                    {{ student.name + ' ' + student.surname }}
                </div>
                <div v-if="student.status_on_training == 'present'" @click="student.status_on_training = 'missing'" class="w-[30%] text-white font-semibold text-center align-middle flex justify-center items-center rounded-r-[10px] bg-[#48E5C2]">
                    Присутній
                </div>
                <div v-else-if="student.status_on_training == 'missing'" @click="student.status_on_training = 'present'" class="w-[30%] text-white font-semibold text-center align-middle flex justify-center items-center rounded-r-[10px] bg-[#BD252C]">
                    Відсутній
                </div>
            </div>        
        </div>

        <div class="w-full py-3 rounded-t-[10px]">    
            <div class="text-[#BD252C] text-center text-[18px] font-semibold">
                Гравців на тренуванні — {{ allInGroupStudents.filter(student => student.status_on_training == 'present').length }}
            </div>
            <div class="mt-[30px]">
                <button @click="additionalStudentsFlag = true" class="bg-[#F8FF82] mb-2 rounded-[9px] text-[#211C1F] px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mx-auto">
                    <div class="w-full text-[#211C1F] font-semibold">Додати учня</div>
                </button>
                <button @click="saveChanges" class="bg-[#48E5C2] mb-2 rounded-[9px] text-[#211C1F] px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mx-auto">
                    <div class="w-full text-white font-semibold">Зберегти зміни</div>
                </button>
                <button @click="deleteTraining" class="bg-[#BD252C] mb-2 rounded-[9px] text-[#211C1F] px-3 py-2 w-[92%] text-base flex items-center align-middle justify-between mx-auto">
                    <div class="w-full text-white font-semibold">Видалити тренування</div>
                </button>
            </div>
        </div>

        <div v-if="additionalStudentsFlag" class="fixed top-0 left-0 w-full h-full bg-[#211C1F]">
            <div class="relative">
                <div>
                    <div @click="additionalStudentsFlag = false" class="flex text-[#FECC00] items-center ml-3 mt-2">
                        <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#FECC00" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
                        </svg>
                        <div class="ml-2">
                            Назад
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-[#A1D9F7] text-[18px] text-center">Додаткові учні</div>
            <div class="flex justify-center mt-[10px]">
                <input v-model="newStudentInput" type="text" class="bg-transparent border-[2px] border-[#A1D9F7] px-3 py-2 w-[92%] rounded-[9px] placeholder:text-[#A1D9F7] placeholder:opacity-60 outline-none select-none text-[#A1D9F7]" placeholder="Ім'я та прізвище учня">
            </div>
            <div class="relative">
                <div v-if="visibleStudents.length > 0" class="flex flex-col items-center absolute w-full">
                    <div v-for="student in visibleStudents" @click="addStudent(student)" class="text-[#A1D9F7] bg-[#A1D9F7] px-3 py-3 border-b-[1px] border-[#211C1F] bg-opacity-30 w-[92%]">
                        <div class="text-[18px]">{{ student.name + ' ' + student.surname }}</div>
                    </div>
                </div>
            </div>
            <div class="flex flex-col items-center mt-[20px]">
                <div v-if="additionalStudents.length > 0" v-for="student in additionalStudents" class="flex mb-2 w-[92%]">
                    <div class="w-[70%] bg-[#A1D9F7] text-[18px] py-[10px] pl-2 rounded-l-[10px] font-semibold">
                        {{ student.name + ' ' + student.surname }}
                    </div>
                    <div @click="deleteAdditionalStudent(student)" class="w-[30%] text-white font-semibold text-center align-middle flex justify-center items-center rounded-r-[10px] bg-[#BD252C]">
                        Видалити
                    </div>
                </div>   
                <div v-else>
                    <div class="text-[#A1D9F7] text-[18px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">Немає доданих учнів</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ref, onMounted, watch } from 'vue'
    import { useMainStore } from '../stores/mainStore'
    import { useRouter } from 'vue-router'
    import { getStudentsWhichAttendedTraining, deleteTrainingById, getStudentsWhichMissedTraining, changeMissedToAttendedTraining, changeAttendedToMissedTraining, addCompletedTrainingToStudent, getAllStudentsRequest, deleteCompletedTrainingFromStudent } from '../services/apiRequests'

    export default {
        name: 'CompletedTrainingView',

        setup() {
            const store = useMainStore()
            const router = useRouter()

            const studentsAttendingTraining = ref([])
            const studentsMissedTraining = ref([])
            const allInGroupStudents = ref([])
            const additionalStudents = ref([])

            const cachedAllStudents = ref([])
            const cachedAdditionalStudents = ref([])

            onMounted(async () => {
                const response = await getStudentsWhichAttendedTraining(store.currentCompletedTraining.date)
                studentsAttendingTraining.value.push(...response.data.map(student => {
                    return {
                        ...student,
                        started_status: 'present',
                        status_on_training: 'present'
                    }
                }))

                const response2 = await getStudentsWhichMissedTraining(store.currentCompletedTraining.date)
                studentsMissedTraining.value.push(...response2.data.map(student => {
                    return {
                        ...student,
                        started_status: 'missing',
                        status_on_training: 'missing'
                    }
                }))

                const allStudents = [...studentsAttendingTraining.value, ...studentsMissedTraining.value]

                allInGroupStudents.value = allStudents
                cachedAllStudents.value = allStudents

                additionalStudents.value = allStudents.filter(student => student.group_id != store.currentCompletedTraining.group_id)
                cachedAdditionalStudents.value = allStudents.filter(student => student.group_id != store.currentCompletedTraining.group_id)

                await getAllStudents()
            })

            const additionalStudentsFlag = ref(false)
            const allStudents = ref([])
            const visibleStudents = ref([])
            const newStudentInput = ref('')
            const getAllStudents = async () => {
                const response = await getAllStudentsRequest()
                allStudents.value = response.data.filter(student => {
                    return !allInGroupStudents.value.some(currentStudent => currentStudent.student_id == student.student_id)
                })
            }

            watch(newStudentInput, (value) => {
                if (value.length > 0) {
                    visibleStudents.value = allStudents.value.filter(student => {
                        return student.name.toLowerCase().includes(value.toLowerCase()) || student.surname.toLowerCase().includes(value.toLowerCase())
                    })
                } else {
                    visibleStudents.value = []
                }
            })
            const addStudent = (student) => {
                const buffer = [...allInGroupStudents.value]
                buffer.push({
                    ...student,
                    status_on_training: 'missing'
                })
                allInGroupStudents.value = buffer
                additionalStudents.value.push(student)
                visibleStudents.value = []
                newStudentInput.value = ''
            }

            const deleteAdditionalStudent = (additionalStudent) => {
                const buffer = [...allInGroupStudents.value]
                const index = buffer.findIndex(student => student.student_id == additionalStudent.student_id)
                buffer.splice(index, 1)
                allInGroupStudents.value = buffer
                const buffer2 = [...additionalStudents.value]
                const index2 = buffer2.findIndex(student => student.student_id == additionalStudent.student_id)
                buffer2.splice(index2, 1)
                additionalStudents.value = buffer2
            }

            const saveChanges = () => {
                const deletedAdditionalStudents = []
                const newAdditionalStudents = []

                const missedToAttendedStudentsDelta = []
                const attendedToMissedStudentsDelta = []
                
                cachedAdditionalStudents.value.forEach(student => {
                    if (!additionalStudents.value.find(additionalStudent => additionalStudent.student_id == student.student_id)) {
                        deletedAdditionalStudents.push(student)
                    }
                })

                additionalStudents.value.forEach(student => {
                    if (!cachedAdditionalStudents.value.find(cachedStudent => cachedStudent.student_id == student.student_id)) {
                        const newStudent = allInGroupStudents.value.find(allStudent => allStudent.student_id == student.student_id)
                        newAdditionalStudents.push(newStudent)
                    }
                })

                allInGroupStudents.value.forEach(student => {
                    if (student.started_status != student.status_on_training) {
                        if (student.started_status == 'present') {
                            attendedToMissedStudentsDelta.push(student)
                        } else {
                            missedToAttendedStudentsDelta.push(student)
                        }
                    }
                })

                deletedAdditionalStudents.forEach(async student => {
                    await deleteCompletedTrainingFromStudent(student.student_id, student.started_status, store.currentCompletedTraining.date)
                })

                newAdditionalStudents.forEach(async student => {
                    await addCompletedTrainingToStudent(student.student_id, student.status_on_training, store.currentCompletedTraining.date)
                })

                missedToAttendedStudentsDelta.forEach(async student => {
                    await changeMissedToAttendedTraining(student.student_id, store.currentCompletedTraining.date)
                })

                attendedToMissedStudentsDelta.forEach(async student => {
                    await changeAttendedToMissedTraining(student.student_id, store.currentCompletedTraining.date)
                })

                router.push('/')
            }

            const deleteTraining = async () => {
                cachedAllStudents.value.forEach(async student => {
                    await deleteCompletedTrainingFromStudent(student.student_id, student.started_status, store.currentCompletedTraining.date)
                })
                await deleteTrainingById(store.currentCompletedTraining.training_id)
                router.push('/')
            }

            return {
                studentsAttendingTraining,
                studentsMissedTraining,
                allInGroupStudents,
                additionalStudentsFlag,
                allStudents,
                visibleStudents,
                newStudentInput,
                getAllStudents,
                additionalStudents,
                addStudent,
                deleteAdditionalStudent,
                saveChanges,
                deleteTraining
            }
        }
    }
</script>
