<template>
    <div class="w-[100px]">
        <div class="mt-[20px]">
            <img src="../assets/Sparta_icon.svg" class="w-[150px] h-[150px]">
        </div>
        <div>
            <div 
                v-for="tab in menuTabs" 
                @click="chooseInMenu(tab)" 

                class="text-white text-center mt-[25px] py-[0.7vh] cursor-pointer text-sm select-none items-center"

                :class="
                    {
                        'bg-gradient-to-l from-[#D9D9D93D]' : route.path == tab.path,
                    }
                    " 
            >
                <div class="flex items-center justify-center">
                    <img :src="tab.icon" class="mx-[0.2vw] w-[25px]">
                </div>
            </div>
            <div @click="logout" class="mt-[10vw] flex justify-center cursor-pointer">
                <img src="../assets/logout_icon.svg" class="w-[25px]">
            </div>
        </div>

    </div>
</template>


<script>
    import { ref } from 'vue'
    import { uuid } from 'vue3-uuid'
    import { useRoute, useRouter } from 'vue-router'

    export default {
        setup() {
            const route = useRoute()
            const router = useRouter()
            const updateKey = ref(uuid.v1())
            const menuTabs = [
                {
                    path: '/',
                    name: 'Показники',
                    icon: require('../assets/sidebar_icons/dashboard_icon.svg')
                },
                {
                    path: '/students',
                    name: 'Учні',
                    icon: require('../assets/sidebar_icons/players_icon.svg')
                },
                {
                    path: '/groups',
                    name: 'Групи',
                    icon: require('../assets/sidebar_icons/groups_icon.svg')
                },
                {
                    path: '/trainings',
                    name: 'Тренування',
                    icon: require('../assets/sidebar_icons/calendar_icon.svg')
                },
                {
                    path: '/finance',
                    name: 'Фінанси',
                    icon: require('../assets/sidebar_icons/finance_icon.svg')
                },
                {
                    path: '/settings',
                    name: 'Налаштування',
                    icon: require('../assets/sidebar_icons/settings_icon.svg')
                }
            ]

            const logout = () => {
                localStorage.removeItem('access_token')
                localStorage.removeItem('refresh_token')
                router.go()
            }

            const chooseInMenu = (tab) => {
                for (let i = 0; i < menuTabs.length; i++) {
                    menuTabs[i].innerPagesView = false
                }
                if (tab.innerPages) {
                    tab.innerPagesView = true
                    updateKey.value = uuid.v1()
                } else {
                    router.push(tab.path)
                }
            }

            return {
                route,
                router,
                menuTabs,
                logout,
                chooseInMenu,
                updateKey
            }
        }
    }
</script>

<style>

    .logo-place {
        box-shadow: inset -5px 0px 10px 3px rgba(0, 0, 0, .4);
    }

</style>