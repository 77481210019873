<template>
    <div>
        <div @click="$router.push('/')" class="flex text-[#FECC00] items-center ml-3 mt-2">
            <svg width="10" height="19" viewBox="0 0 10 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 17L1.56957 10.8258C0.810144 10.0966 0.810144 8.90341 1.56957 8.17424L8 2" stroke="#FECC00" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round"/>
            </svg>
            <div class="ml-2">
                Назад
            </div>
        </div>

        <div class="border-b-[1px] uppercase border-white pb-[10px] w-[90%] mx-auto text-center font-semibold text-white mt-[10px]">
            ВСІ повідомлення
        </div>

        <div v-for="month in allSendedMessages" class="mt-5 mb-10">
            <div class="text-center text-white text-[13px] font-light leading-tight">{{ month.month_name }}</div>

            <div v-for="message in month.messages" @click="selectMessage(message)" class="w-[90%] mt-3 mx-auto relative flex h-[58px] bg-[#47484966] bg-opacity-5 rounded-[8px] border-[1px] border-[#8C8C8C33] mb-2">
                <div v-if="message.sender == 'trainer'" class="h-full rounded-l-[8px] bg-green-400 w-[7px]"></div>
                <div v-if="message.sender == 'system'" class="h-full rounded-l-[8px] bg-[#BD252C] w-[7px]"></div>
                <div v-if="message.sender == 'admin'" class="h-full rounded-l-[8px] bg-green-400 w-[7px]"></div>
                <div class="w-[80%] flex pl-[4px] text-white align-middle items-center justify-center">
                    <div class="text-white text-[9px] mr-[8px] font-light leading-tight w-[30%]">{{ message.date }}</div>
                    <div class="text-[10px] align-middle w-[60%] overflow-hidden h-[85%] flex items-center text-[#fff]">{{ message.text.slice(0, 60) }}{{ message.text.length > 60 ? '...' : '' }}</div>
                </div>
                <div v-if="message.sender == 'system'" class="flex justify-center items-center w-[18.5%] h-[11px] top-[50%] absolute right-[5px] translate-y-[-50%] bg-[#BD252C] rounded-full">
                    <div class="w-[42px] h-2.5 relative bottom-[1px] text-center flex items-center justify-center text-white text-[9px] font-normal leading-tight">система</div>
                </div>
                <div v-if="message.sender == 'trainer'" class="flex justify-center items-center w-[18.5%] h-[11px] top-[50%] absolute right-[5px] translate-y-[-50%] bg-green-400 rounded-full">
                    <div class="w-[42px] h-2.5 relative bottom-[1px] text-center flex items-center justify-center text-[#211C1F] text-[9px] font-normal leading-tight">надіслано</div>
                </div>
            </div>
            
        </div>
            
    </div>
</template>

<script>
    import { ref, onMounted } from 'vue'
    import { useRouter } from 'vue-router'
    import { useMainStore } from '../stores/mainStore'
    import { getAllBroadcastedMessages } from '@/mobileApp/services/apiRequests'

    export default {
        name: 'all-messages-view',

        setup() {
            const allSendedMessages = ref([])
            const store = useMainStore()
            const router = useRouter()

            const formateMonthAndYear = (date) => {
                const month = date.slice(5, 7)
                const year = date.slice(0, 4)

                switch (month) {
                    case '01':
                        return `СІЧЕНЬ ${year}`
                    case '02':
                        return `ЛЮТИЙ ${year}`
                    case '03':
                        return `БЕРЕЗЕНЬ ${year}`
                    case '04':
                        return `КВІТЕНЬ ${year}`
                    case '05':
                        return `ТРАВЕНЬ ${year}`
                    case '06':
                        return `ЧЕРВЕНЬ ${year}`
                    case '07':
                        return `ЛИПЕНЬ ${year}`
                    case '08':
                        return `СЕРПЕНЬ ${year}`
                    case '09':
                        return `ВЕРЕСЕНЬ ${year}`
                    case '10':
                        return `ЖОВТЕНЬ ${year}`
                    case '11':
                        return `ЛИСТОПАД ${year}`
                    case '12':
                        return `ГРУДЕНЬ ${year}`
                }
            }

            const selectMessage = (message) => {
                store.currentMessage = message
                router.push('/current-message')
            }

            onMounted(async () => {
                const messages = (await getAllBroadcastedMessages(store.trainer.trainer_id, 20, 0)).data.messages
                allSendedMessages.value = []
                console.log(messages)
                messages.forEach(message => {
                    const month = message.date.slice(0, 7)
                    const monthIndex = allSendedMessages.value.findIndex(month => month.month_code == message.date.slice(0, 7))
                    if (monthIndex == -1) {
                        allSendedMessages.value.unshift({
                            month_name: formateMonthAndYear(message.date),
                            month_code: month,
                            messages: [{
                                date: message.date.split('T')[0].split('-').reverse().join('.') + ' ' + message.date.split('T')[1].split(':').slice(0, 2).join(':'),
                                text: message.text,
                                sender: message.sender_type
                            }]
                        })
                    } else {
                        allSendedMessages.value[monthIndex].messages.unshift({
                            date: message.date.split('T')[0].split('-').reverse().join('.') + ' ' + message.date.split('T')[1].split(':').slice(0, 2).join(':'),
                            text: message.text,
                            sender: message.sender_type
                        })
                    }
                })

                console.log(allSendedMessages.value)
            })

            return {
                allSendedMessages,
                selectMessage
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>